import { CurrencyExchange } from "@mui/icons-material";
// import { filterSousMenu } from "menu-items";
import { UserModule } from "types";
import { LINKS } from "utils/constant";

const sousmenus = [
  {
    id: "collecte",
    title: "Collectes",
    type: "item",
    url: LINKS.collectes.list,
    caption: "Consulter et gérer les collectes de fonds",
  },
  {
    id: "collecte_tournee",
    title: "Tournées",
    type: "item",
    url: LINKS.collectesTournees.list,
    caption: "Consulter et gérer les tournées pour la collecte de fonds",
  },
  {
    id: "pre-tire",
    title: "Pré-tri",
    type: "item",
    url: LINKS.pretris.list,
    caption: "Consulter et gérer les pre tris",
  },
  {
    id: "mouvements",
    title: "Mouvements",
    type: "item",
    url: LINKS.mouvements.list,
  },
  {
    id: "coffre-fort",
    title: "Coffres forts clients",
    type: "item",
    url: LINKS.coffresForts.list,
  },
  {
    id: "arrete-caisse",
    title: "Arrêtés de caisses",
    type: "item",
    url: LINKS.arretesCaisses.list,
  },
];

export const buildCaisseMenu = (userModule: UserModule) => {
  const data = sousmenus; //filterSousMenu(userModule, sousmenus);

  if (data.length) {
    return {
      id: "group-caisse",
      type: "group",
      children: [
        {
          id: "menu-caisse",
          title: "Gestion de caisse",
          type: "collapse",
          icon: CurrencyExchange,
          children: data,
        },
      ],
    };
  }
  return null;
};
