import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ArreteCaisseResource,
  CoffreFortClientResource,
  CollecteResource,
  CollecteTourneeResource,
  PretriResource,
  SituationCoffreResource,
} from "types/caisse.type";
import { FilterProps, ListPaginated } from "types/list.type";

type caisseState = {
  collectes: ListPaginated<CollecteResource>;
  collecteFilter: FilterProps;

  tournees: ListPaginated<CollecteTourneeResource>;
  tourneeFilter: FilterProps;

  pretris: ListPaginated<PretriResource>;
  pretrisFilter: FilterProps;

  coffreFortClient: ListPaginated<CoffreFortClientResource>;
  coffreFortClientFilter: FilterProps;

  mouvements: ListPaginated<SituationCoffreResource>;
  mouvementsFilter: FilterProps;

  arretecaisses: ListPaginated<ArreteCaisseResource>;
  arretecaisseFilter: FilterProps;
};

const initialState: caisseState = {
  pretris: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  pretrisFilter: {
    page: 1,
    perpage: 10,
  },

  collectes: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  collecteFilter: {
    page: 1,
    perpage: 10,
  },

  tournees: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  tourneeFilter: {
    page: 1,
    perpage: 10,
  },

  coffreFortClient: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
  },
  coffreFortClientFilter: {
    page: 1,
    perpage: 10,
  },

  mouvements: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
  },
  mouvementsFilter: {
    page: 1,
    perpage: 10,
  },

  arretecaisses: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
  },
  arretecaisseFilter: {
    page: 1,
    perpage: 10,
  },
};

const caisseSlice = createSlice({
  name: "collecte",
  initialState,
  reducers: {
    setCollectes(
      state,
      action: PayloadAction<ListPaginated<CollecteResource>>
    ) {
      state.collectes = action.payload;
    },
    setCollecteFilter(state, { payload }: PayloadAction<Record<string, any>>) {
      state.collecteFilter = payload;
    },

    setCollectesTournees(
      state,
      action: PayloadAction<ListPaginated<CollecteTourneeResource>>
    ) {
      state.tournees = action.payload;
    },
    setCollecteTourneeFilter(
      state,
      { payload }: PayloadAction<Record<string, any>>
    ) {
      state.tourneeFilter = payload;
    },

    setCoffreFortsClients(
      state,
      action: PayloadAction<ListPaginated<CoffreFortClientResource>>
    ) {
      state.coffreFortClient = action.payload;
    },
    setCoffreFortClientFilter(
      state,
      { payload }: PayloadAction<Record<string, any>>
    ) {
      state.coffreFortClientFilter = payload;
    },

    setMouvementsCoffres(
      state,
      action: PayloadAction<ListPaginated<SituationCoffreResource>>
    ) {
      state.mouvements = action.payload;
    },
    setMouvementCoffreFilter(
      state,
      { payload }: PayloadAction<Record<string, any>>
    ) {
      state.mouvementsFilter = payload;
    },

    setPretris(state, action: PayloadAction<ListPaginated<PretriResource>>) {
      state.pretris = action.payload;
    },
    setPretrisFilter(state, { payload }: PayloadAction<Record<string, any>>) {
      state.pretrisFilter = payload;
    },

    setArretecaisses(
      state,
      action: PayloadAction<ListPaginated<ArreteCaisseResource>>
    ) {
      state.arretecaisses = action.payload;
    },
    setArretecaisseFilter(
      state,
      { payload }: PayloadAction<Record<string, any>>
    ) {
      state.arretecaisseFilter = payload;
    },
  },
});

export default caisseSlice.reducer;

export const {
  setCollecteFilter,
  setCollectes,
  setCollectesTournees,
  setCollecteTourneeFilter,
  setCoffreFortClientFilter,
  setCoffreFortsClients,
  setMouvementCoffreFilter,
  setMouvementsCoffres,
  setPretris,
  setPretrisFilter,
  setArretecaisseFilter,
  setArretecaisses,
} = caisseSlice.actions;

const getCaisseReducer = (state: any): caisseState => state.caisse;

export const selectCollectes = createSelector(
  getCaisseReducer,
  ({ collectes }) => collectes
);

export const selectCollecteFilter = createSelector(
  getCaisseReducer,
  ({ collecteFilter }) => collecteFilter
);

export const selectPretris = createSelector(
  getCaisseReducer,
  ({ pretris }) => pretris
);

export const selectPretrisFilter = createSelector(
  getCaisseReducer,
  ({ pretrisFilter }) => pretrisFilter
);

export const selectCollectesTournees = createSelector(
  getCaisseReducer,
  ({ tournees }) => tournees
);

export const selectCollecteTourneeFilter = createSelector(
  getCaisseReducer,
  ({ tourneeFilter }) => tourneeFilter
);

export const selectCoffreFortsClients = createSelector(
  getCaisseReducer,
  ({ coffreFortClient }) => coffreFortClient
);

export const selectCoffreFortClientFilter = createSelector(
  getCaisseReducer,
  ({ coffreFortClientFilter }) => coffreFortClientFilter
);

export const selectMouvementsCoffres = createSelector(
  getCaisseReducer,
  ({ mouvements }) => mouvements
);

export const selectMouvementFilter = createSelector(
  getCaisseReducer,
  ({ mouvementsFilter }) => mouvementsFilter
);

export const selectArretecaisses = createSelector(
  getCaisseReducer,
  ({ arretecaisses }) => arretecaisses
);

export const selectArretecaisseFilter = createSelector(
  getCaisseReducer,
  ({ arretecaisseFilter }) => arretecaisseFilter
);
